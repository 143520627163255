<template>
    <div class="case">
        <div class="banner">
            <img src="./image/case-banner.png" alt="">
        </div>
        <div class="content">
            <div class="title">
                <span></span>
                客户案例
            </div>
            <div class="section clear_fix">
                <div class="item fl">
                    <img class="fl" src="./image/product-item1.png" alt="">
                    <div class="text fl">
                        <b>商城APP</b>
                        <p>是商务活动中各参与方和支持企业 进行交易活动的电子技术手段的集 合。狭义上讲，电子商务系统则是 指企业、消费者银行</p>
                    </div>
                </div>
                <div class="item fl">
                    <img class="fl" src="./image/product-item1.png" alt="">
                    <div class="text fl">
                        <b>商城APP</b>
                        <p>是商务活动中各参与方和支持企业 进行交易活动的电子技术手段的集 合。狭义上讲，电子商务系统则是 指企业、消费者银行</p>
                    </div>
                </div>
                <div class="item fl">
                    <img class="fl" src="./image/product-item1.png" alt="">
                    <div class="text fl">
                        <b>商城APP</b>
                        <p>是商务活动中各参与方和支持企业 进行交易活动的电子技术手段的集 合。狭义上讲，电子商务系统则是 指企业、消费者银行</p>
                    </div>
                </div>
                <div class="item fl">
                    <img class="fl" src="./image/product-item1.png" alt="">
                    <div class="text fl">
                        <b>商城APP</b>
                        <p>是商务活动中各参与方和支持企业 进行交易活动的电子技术手段的集 合。狭义上讲，电子商务系统则是 指企业、消费者银行</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" src="./case.scss" scoped></style>

<script>
import { reactive, ref, getCurrentInstance, onMounted } from "vue";
export default {
    setup(props) {
        const data = reactive({
            menu:[{
                title:"社交电商"
            },{
                title:"供应链"
            },{
                title:"系统服务商"
            },{
                title:"信息化"
            }],
            tabIndex:0
        });

        const change_tab_item = (index)=>{
            data.tabIndex = index;
        }

        return{
            data,
            change_tab_item
        }
    }
};
</script>
